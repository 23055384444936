<template>
  <div>
    <a-modal
      v-model="visibleModalManageProgram"
      class="mod"
      :title="
        status.isEdit
          ? 'Form Edit'
          : `Form Add ${addTo === 'topik' ? 'Topic' : 'Basic Competence'}`
      "
      @cancel="toggleModalManageProgram"
      centered
    >
      <form-topik
        v-if="addTo === 'topik'"
        :params="selectedParams"
        :listSelected="rowSessionSelected"
        :data="newData"
        @handle-change="handleChange"
      />
      <form-kd
        v-if="addTo === 'kd'"
        :params="selectedParams"
        :listSelected="rowSessionSelected"
        :data="newData"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageProgram">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageProgram"
          @click="handleOkModalManageProgram"
        >
          {{ status.isEdit ? "Edit" : "Add" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-lg-flex align-items-center">
      <a-select
        @change="changeMapel()"
        style="min-width: 150px"
        :style="{
          display: !isDesktop ? 'block' : null,
        }"
        :class="isDesktop ? 'mr-3' : 'mb-3'"
        v-model="selectedParams.id_mata_pelajaran"
        size="large"
      >
        <a-select-option
          v-for="subject in subjects"
          :value="subject.id"
          :key="subject.id"
          >{{ subject.nama }}</a-select-option
        ></a-select
      >
      <a-select
        style="min-width: 150px"
        @change="handleChangeLevel"
        :style="{
          display: !isDesktop ? 'block' : null,
        }"
        :class="!isDesktop ? 'mb-3' : null"
        v-model="selectedParams.id_level"
        size="large"
      >
        <a-select-option
          v-for="level in levels"
          :value="level.id"
          :key="level.id"
          >{{ level.nama }}</a-select-option
        ></a-select
      >
    </div>
    <!-- <div class="row mt-4">
      <div class="col-lg-4">
        <h3>Total Session</h3>
        <p>123 Session(s)</p>
      </div>
      <div class="col-lg-4">
        <h3>Planned Topic</h3>
        <p>60 Session(s)</p>
      </div>
      <div class="col-lg-4">
        <h3>Planned KD</h3>
        <p>8 Session(s)</p>
      </div>
    </div> -->
    <div class="mt-4">
      <a-table
        size="small"
        :columns="programColumns"
        :data-source="programDataTable"
        :loading="programLoadingTable"
        :pagination="programPagination"
        @change="handleTableChange"
        :row-selection="rowSelection"
        bordered
      >
        <div
          slot="title"
          class="d-lg-flex flex-column flex-lg-row align-items-center"
        >
          <a-range-picker
            :class="!isDesktop ? 'w-100' : null"
            size="large"
            :value="selectedDayRange"
            @change="onChangeDateRange"
          />
          <a-button
            @click="getCurrentWeek"
            class="ml-0 ml-lg-3 mt-3 mt-lg-0"
            size="large"
            >Jump to Current Week</a-button
          >
          <div class="ml-0 ml-lg-auto mt-3 mt-lg-0">
            <a-dropdown :disabled="!rowSessionSelected.length">
              <a-menu slot="overlay" @click="handleMenuClick">
                <a-menu-item key="topik"> Topic </a-menu-item>
                <a-menu-item key="kd"> Basic Competence </a-menu-item>
              </a-menu>
              <a-button :block="!isDesktop" size="large" type="primary">
                Add {{ rowSessionSelected.length }} Session(s) to
                <a-icon type="down" />
              </a-button>
            </a-dropdown>
          </div>
        </div>
        <div
          slot="tanggal_sesi"
          slot-scope="value, record"
          class="d-flex flex-column"
        >
          <p class="mb-0">
            Class {{ record.slot.kela.tingkat }}-{{ record.slot.kela.simbol }}
          </p>
          <a @click="toDetailSession(record.id)" class="mb-0"
            >(Week {{ record.week.week }})
            {{ moment(value).format("dddd, DD MMM YYYY") }}</a
          >
          <!-- <a-button
            @click="toDetailSession(record.id)"
            size="large"
            class="btn btn-outline-primary rounded mt-3"
            >Detail</a-button
          > -->
        </div>
        <div
          slot="topik"
          slot-scope="value"
          class="d-flex align-items-center justify-content-center"
        >
          <a-popover v-if="value">
            <div style="width: 400px" slot="content">
              <h5>Topic Detail</h5>
              <a-descriptions class="mt-3" size="small" bordered :column="1">
                <a-descriptions-item class="w-1" label="Topic"
                  ><p>{{ value.nama }}</p></a-descriptions-item
                >
                <a-descriptions-item class="w-1" label="Subtopic"
                  ><p>
                    {{ value.subtopik }}
                  </p></a-descriptions-item
                >
              </a-descriptions>
            </div>
            <span role="button"
              ><a-icon
                class="text-success"
                type="check-circle"
                :style="{ fontSize: '20px' }"
            /></span>
          </a-popover>
          <a-popover v-else>
            <div slot="content">
              <p>Not Planned Yet</p>
            </div>
            <span role="button"
              ><a-icon
                class="text-warning"
                type="exclamation-circle"
                :style="{ fontSize: '20px' }"
            /></span>
          </a-popover>
        </div>
        <div
          slot="tipe_assignment"
          slot-scope="value"
          class="d-flex align-items-center justify-content-center"
        >
          <a-icon
            type="paper-clip"
            v-if="value == 'File Upload'"
            style="font-size: 20px; color: #1b55e3"
          />
          <a-icon
            type="align-left"
            v-else-if="value == 'Essay'"
            style="font-size: 20px; color: #1b55e3"
          />
          <a-icon
            type="unordered-list"
            v-else-if="value == 'Multiple Choice'"
            style="font-size: 20px; color: #1b55e3"
          />
          <a-icon v-else type="minus-circle" style="font-size: 20px" />
        </div>
        <div
          slot="kd_master"
          slot-scope="value"
          class="d-flex align-items-center justify-content-center"
        >
          <a-popover v-if="value">
            <div style="width: 400px" slot="content">
              <h5>Basic Competence's Detail</h5>
              <a-descriptions class="mt-3" size="small" bordered :column="1">
                <a-descriptions-item class="w-1" label="No."
                  ><p>{{ value.no }}</p></a-descriptions-item
                >
                <a-descriptions-item class="w-1" label="Knowledge"
                  ><p>
                    {{ value.nama_pengetahuan }}
                  </p></a-descriptions-item
                >
                <a-descriptions-item class="w-1" label="Skill"
                  ><p>
                    {{ value.nama_keterampilan }}
                  </p></a-descriptions-item
                >
              </a-descriptions>
            </div>
            <span role="button"
              ><a-icon
                class="text-success"
                type="check-circle"
                :style="{ fontSize: '20px' }"
            /></span>
          </a-popover>
          <a-popover v-else>
            <div slot="content">
              <p>Not Planned Yet</p>
            </div>
            <span role="button"
              ><a-icon
                class="text-warning"
                type="exclamation-circle"
                :style="{ fontSize: '20px' }"
            /></span>
          </a-popover>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const formTopik = () => import('@/views/Teacher/Planning/New/Session/Form/Topik')
const formKd = () => import('@/views/Teacher/Planning/New/Session/Form/Kd')

const programColumns = [
  {
    title: 'Session',
    dataIndex: 'tanggal_sesi',
    key: 'tanggal_sesi',
    scopedSlots: { customRender: 'tanggal_sesi' },
    width: 290,
    // fixed: 'left',
    // sorter: true,
  },
  {
    title: 'Topic',
    dataIndex: 'topik',
    key: 'topik',
    scopedSlots: { customRender: 'topik' },
    align: 'center',
    // width: 320,
  },
  {
    title: 'Assignment',
    dataIndex: 'tipe_assignment',
    key: 'tipe_assignment',
    scopedSlots: { customRender: 'tipe_assignment' },
    align: 'center',
    // width: 320,
  },
  {
    title: 'Basic Competence',
    dataIndex: 'kd_master',
    key: 'kd_master',
    scopedSlots: { customRender: 'kd_master' },
    align: 'center',
    // width: 320,
    // children: [
    //   {
    //     title: 'No',
    //     dataIndex: 'no',
    //     key: 'no',
    //     scopedSlots: { customRender: 'kd_master' },
    //     align: 'center',
    //     // width: 200,
    //   },
    //   {
    //     title: 'Knowledge',
    //     dataIndex: 'nama_pengetahuan',
    //     key: 'nama_pengetahuan',
    //     scopedSlots: { customRender: 'kd_master' },
    //     // width: 350,
    //   },
    //   {
    //     title: 'Skill',
    //     dataIndex: 'nama_keterampilan',
    //     key: 'nama_keterampilan',
    //     scopedSlots: { customRender: 'kd_master' },
    //     // width: 350,
    //   },
    // ],
  },
]
export default {
  name: 'Program',
  components: {
    formTopik,
    formKd,
  },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
    },
  },
  data() {
    return {
      programColumns,
      visibleModalManageProgram: false,
      loadingActionModalManageProgram: false,
      loadingDeleteProgram: false,
      programLoadingTable: false,
      programDataTable: [],
      programPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newData: {
        id: null,
      },
      idEdit: null,
      selectedParams: {
        id_mata_pelajaran: null,
        id_level: null,
      },
      selectedDayRange: null,
      rowSessionSelected: [],
      addTo: null,
      sortBy: '',
      order: '',
      moment,
    }
  },
  methods: {
    handleChangeLevel() {
      this.rowSessionSelected = []
      this.fetchDataProgram()
    },
    toDetailSession(id) {
      const routeData = this.$router.resolve({ name: 'Detail Schedule Teacher', params: { id } })
      window.open(routeData.href, '_blank')
    },
    handleChange(payload) {
      const { value, column } = payload
      // console.log('parent', payload)
      this.newData[column] = value
    },
    onChangeDateRange(date, dateString) {
      this.selectedDayRange = dateString
      this.fetchDataProgram()
    },
    handleMenuClick(e) {
      console.log('Click', e)
      this.addTo = e.key
      this.toggleModalManageProgram('add')
    },
    toggleModalManageProgram(status, data) {
      this.visibleModalManageProgram = !this.visibleModalManageProgram
      if (!this.visibleModalManageProgram) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newData = {
            id: null,
          }
          this.addTo = null
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newData = {
            ic: data.nama,
          }
        }
      }
    },
    handleOkModalManageProgram() {
      const content = `${this.status.isEdit ? `Are you sure want to edit ${this.rowSessionSelected.length} session(s) to this ${this.addTo === 'topik' ? 'topic' : 'basic competence'}?` : `Are you sure want to add ${this.rowSessionSelected.length} session(s) to this ${this.addTo === 'topik' ? 'topic' : 'basic competence'}?`}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageProgram = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataProgram: this.newProgram,
            } : {
              dataId: this.newData,
              dataSesi: this.rowSessionSelected,
            }
            this.$store.dispatch(`sesi/${this.status.isEdit ? 'EDIT' : 'POST'}_SESI_TO_${this.addTo === 'topik' ? 'TOPIK' : 'KD'}`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageProgram = false
                this.toggleModalManageProgram()
                this.rowSessionSelected = []
                this.fetchDataProgram()
                if (isSuccess) {
                  const description = this.status.isEdit ? `${this.addTo === 'topik' ? 'topic' : 'basic competence'} has been updated` : `${this.addTo === 'topik' ? 'topic' : 'basic competence'} has been added`
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? `${this.addTo === 'topik' ? 'topic' : 'basic competence'} has not been updted` : `${this.addTo === 'topik' ? 'topic' : 'basic competence'} has not been added`
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Add Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.programPagination }
      this.sortBy = sorter.columnKey
      pager.current = pagination.current
      this.programPagination = pager
      // if (sorter.order === 'ascend') {
      //   this.order = 'ASC'
      // } else if (sorter.order === 'descend') {
      //   this.order = 'DESC'
      // } else {
      //   this.order = 'ASC'
      // }
      // this.rowSessionSelected = []
      // this.fetchDataProgram()
    },
    fetchDataProgram(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.programLoadingTable = true
      // console.log(this.programPagination)
      this.programPagination.current = 1
      this.$store.dispatch('sesi/FETCH_LIST_SESI', {
        page: this.programPagination.current,
        order: this.order,
        search: this.search,
        sortBy: this.sortBy,
        idMataPelajaran: this.selectedParams.id_mata_pelajaran,
        idLevel: this.selectedParams.id_level,
        dates: this.selectedDayRange,
      })
        .then(res => {
          this.programLoadingTable = false
          const pagination = { ...this.programPagination }
          pagination.total = res.total
          this.programPagination = pagination
          this.rowSessionSelected = []
          // console.log(res)
          this.programDataTable = res.dataTable.map(el => {
            return {
              ...el,
              key: el.id,
              no: el.kd_master ? el.kd_master.no : null,
              nama_pengetahuan: el.kd_master ? el.kd_master.nama_pengetahuan : null,
              nama_keterampilan: el.kd_master ? el.kd_master.nama_keterampilan : null,
            }
          })
        })
    },
    getCurrentWeek() {
      var currentDate = moment()
      var weekStart = currentDate.clone().startOf('isoWeek')
      // var weekEnd = currentDate.clone().endOf('isoWeek')

      var days = []

      for (var i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').locale('id').format('YYYY-MM-DD'))
      }

      console.log(days)

      this.selectedDayRange = [days[0], days[6]]
      this.fetchDataProgram()
    },
    async changeMapel() {
      try {
        this.$emit('changeMapel', this.selectedParams.id_mata_pelajaran)
        this.selectedParams.id_level = this.levels[0]?.id
        // this.programPagination.current = 1
        await this.fetchDataProgram()
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    this.selectedParams.id_mata_pelajaran = this.subjects[0]?.id
    this.changeMapel()
  },
  computed: {
    isValidateForm() {
      if (this.newData.id) {
        return false
      }
      return true
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(selectedRows)
          this.rowSessionSelected = selectedRows.filter(row => selectedRowKeys.find(key => key === row.id))
          // console.log(this.rowSessionSelected)
        },
        selectedRowKeys: this.rowSessionSelectedKey,
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    rowSessionSelectedKey() {
      return this.rowSessionSelected.map(row => row.key)
    },
  },
}
</script>

<style>
</style>
